<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Clientes
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'projects')">mdi-factory</v-icon></template><span>Proyectos asignados</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'products')">mdi-archive-arrow-up</v-icon></template><span>Productos asignados</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="warning" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewMap(item)">mdi-eye</v-icon></template><span>Mostrar en mapa</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idcliente)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nuevo cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :rules="rules.empresa" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.codigo" label="Código" :rules="rules.codigo" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.latitud" label="Latitud" :rules="rules.latitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.longitud" label="Longitud" :rules="rules.longitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.cuadrante" label="Cuadrante" :rules="rules.cuadrante" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.email" label="Correo" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :rules="rules.empresa" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.codigo" label="Código" :rules="rules.codigo" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.latitud" label="Latitud" :rules="rules.latitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.longitud" label="Longitud" :rules="rules.longitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.cuadrante" label="Cuadrante" :rules="rules.cuadrante" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.email" label="Correo" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.projects" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Proyectos asignados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formProjects" lazy-validation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in forms.update.proyectos">
                  <v-col cols="12" :key="idx">
                    <v-text-field :value="item" readonly dense outlined required></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.products" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Productos asignados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formProducts" lazy-validation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in forms.update.productos">
                  <v-col cols="12" :key="idx">
                    <v-text-field :value="item" readonly dense outlined required></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el cliente?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'client',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Empresa', align: 'left', sortable: false, value: 'empresa.nombre' },
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Código', align: 'left', sortable: false, value: 'codigo' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      empresas: []
    },
    forms: {
      new: {
        empresa: '',
        nombre: '',
        codigo: '',
        latitud: '',
        longitud: '',
        cuadrante: '',
        telefono: '',
        email: ''
      },
      update: {
        idcliente: '',
        empresa: '',
        nombre: '',
        codigo: '',
        latitud: '',
        longitud: '',
        cuadrante: '',
        telefono: '',
        email: ''
      }
    },
    rules: {
      empresa: [
        v => !!v || 'El campo es requerido'
      ],
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
      codigo: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
      latitud: [
        v => !!v || 'El campo es requerido',
        v => /^\-?\d+\.\d+?$/.test(v) || 'El campo es inválido'
      ],
      longitud: [
        v => !!v || 'El campo es requerido',
        v => /^\-?\d+\.\d+?$/.test(v) || 'El campo es inválido'
      ],
      cuadrante: [
        v => !!v || 'El campo es requerido',
        v => (/^\d{1,10}$/.test(v) && parseInt(v) >= 0 && parseInt(v) <= 9999999999) || 'El campo es inválido'
      ],
      telefono: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 15) || 'El campo excede la longitud máxima'
      ],
      email: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'El campo es inválido'
      ]
    },
    dialogs: {
      new: false,
      update: false,
      projects: false,
      products: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const company = await this.axios.get(services.routes.company)
      if (company.data.data) {
        this.catalogues.empresas = company.data.data
      }
      this.axios.get(services.routes.client)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          this.forms.new[key] = ''
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      this.dialogs.projects = false
      this.dialogs.products = false
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        data.ubicacion = {
          type: "Point",
          coordinates: [data.latitud, data.longitud]
        }
        this.axios.post(services.routes.client, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Cliente creado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data, type) {
      this.forms.update = {
        idcliente: data.idcliente,
        empresa: data.empresa.idempresa,
        nombre: data.nombre,
        codigo: data.codigo,
        latitud: data.ubicacion.coordinates[0],
        longitud: data.ubicacion.coordinates[1],
        cuadrante: data.cuadrante,
        telefono: data.detalles_clientes[0].telefono,
        email: data.detalles_clientes[0].email
      }
      if (type === 'projects') {
        this.forms.update.proyectos = []
        for (let i = 0; i < data.proyectos.length; i++) {
          this.forms.update.proyectos.push(data.proyectos[i].nombre)
        }
        this.dialogs.projects = true
      }
      else if (type === 'products') {
        this.forms.update.productos = []
        for (let i = 0; i < data.productos.length; i++) {
          this.forms.update.productos.push(data.productos[i].nombre)
        }
        this.dialogs.products = true
      }
      else {
        this.dialogs.update = true
      }
    },
    viewMap(data) {
      const link = `http://maps.google.com/?q=${data.ubicacion.coordinates[0]},${data.ubicacion.coordinates[1]}`
      window.open(link, '_blank')
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        data.ubicacion = {
          type: "Point",
          coordinates: [data.latitud, data.longitud]
        }
        this.axios.put(services.routes.client, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Cliente actualizado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        this.axios.delete(services.routes.client, { data: { data: { idcliente: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Cliente eliminado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
